import Axios from 'axios';

export default {
  data() {
    return {
      TOKEN: this.$store.state.token,
      me: this.$store.state.me,
      company: {
      },
      newUser: {
        name: '',
        email: '',
        errorName: false,
        errorEmail: false,
      }
    }
  },

  mounted() {
    var _this = this;

    axios
      .get('/api/v1/companies/' + this.me.company_id, {
        headers: {
          Authorization: 'Bearer ' + this.TOKEN,
        }
      })
      .then(response => {
        if (!response.data.error) {
          _this.company = response.data.data;
        } else {
          console.log(response.data.error);
        }
    });
  },

  methods: {
    addUserToCompany: function() {
      var _this = this;

      let data = {
        name: _this.newUser.name,
        email: _this.newUser.email,
      }
      // valida os dados do funcionário
      if (data.name == '') {
        _this.newUser.errorName = 'Campo obrigatório.';
      } else {
        _this.newUser.errorName = false;
      }
      if (data.email == '') {
        _this.newUser.errorEmail = 'Campo obrigatório.';
      } else {
        _this.newUser.errorEmail = false;
      }
      if (_this.newUser.errorName || _this.newUser.errorEmail) {
        return;
      }
      // manda os dados do novo funcionário da empresa
      axios
        .post('/api/v1/companies/' + _this.company.id + '/add-user', data, {
          headers: {
            Authorization: 'Bearer ' + _this.TOKEN,
          }
        })
        .then(response => {
          if (response.data.error) {
            // deu erro, avisa quais foram os erros
            if (typeof response.data.error.name != 'undefined') _this.newUser.errorName = response.data.error.name.join(' ');
            if (typeof response.data.error.email != 'undefined') _this.newUser.errorEmail = response.data.error.email.join(' ');
          } else {
            // deu certo, atualiza a lista da equipe da empresa
            axios
              .get('/api/v1/companies/' + _this.company.id, {
                headers: {
                  Authorization: 'Bearer ' + _this.TOKEN,
                }
              })
              .then(response => {
                _this.company = response.data.data;
            });
            // tudo certo, fecha o modal
            $('#add-new-member-modal').modal('hide');

          }
      });

    }
  }
}
